.app-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.chat-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #872ff7;
  padding: 10px;
  color: white;
  z-index: 10;
}

#bot-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: #ffffff;
  border: 1px solid white !important;
  padding: 4px;
}

.bot-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.chat-header-details h2 {
  margin: 0;
  font-size: 18px;
}
.chat-header-details span {
  font-size: 12px;
  color: #e0e0e0;
}
.chat-window {
  flex-grow: 1;
  padding-top: 60px; /* Enough space for the fixed header */
  padding-bottom: 60px; /* Enough space for the fixed input */
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.chat-messages {
  flex-grow: 1;
  /* overflow-y: auto; */
  margin-bottom: 10px;
  padding: 20px;
}

.message {
  margin: 10px 0;
  max-width: 80%;
  /* margin: 10px 0; */
}

.user-message {
  text-align: right;
  background-color: #dcf8c6;
  padding: 10px;
  border-radius: 20px 20px 0 20px;
  margin-left: auto;
  /* display: inline-block;
  text-align: right;
  background-color: #dcf8c6;
  padding: 10px;
  border-radius: 20px 20px 0 20px;
  margin-left: auto; */
}

.bot-message {
  text-align: left;
  background-color: #f1f0f0;
  padding: 10px;
  border-radius: 20px 20px 20px 0;
}

.bot-message-typing-indicator {
  text-align: left;
}

.message-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 5px;
}

.timestamp {
  color: gray;
}

.feedback-buttons {
  display: flex;
  gap: 5px;
}
.feedback-buttons button {
  background: none;
  border: none;
  cursor: pointer;
}
.like-button:hover,
.dislike-button:hover {
  color: #007bff;
}

/* .chat-input {
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  z-index: 10;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
} */

.chat-input {
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  background-color: #f9f9f9;
  z-index: 10;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-size: 16px;
}

/* .chat-input button {
  background-color: #872ff7;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #4540f7;
} */
.chat-input .user-button {
  background-color: #872ff7;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.chat-input .user-button:hover {
  background-color: #4540f7;
}

.chat-input .other-icons-button {
  /* background-color: #888888; */
  background-color: #ffffff;
  color: #888888;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.chat-input .other-icons-btn-img {
  /* background-color: #888888; */
  background-color: #ffffff;
  color: #888888;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.chat-input .send-button {
  background-color: #eeeeee;
  color: #888888;
  border: 1px solid #cfcece;
  padding: 10px 10px;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.typing-indicator {
  font-style: italic;
  color: #808080;
}

.bot-message-prompt {
  max-width: 80% !important;
}

.bot-message-prompt p {
  /* border: 1px solid #1a73e8 !important;
  background-color: #ffffff !important;
  color: #1a73e8 !important;
  font-size: 14px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  font-weight: 500;
  max-width: 80%;
  min-width: auto; */
  border: 1px solid #1a73e8 !important;
  background-color: #ffffff !important;
  color: #1a73e8 !important;
  font-size: 14px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  font-weight: 500;
  display: inline-block; /* Allows the width to fit the content */
  white-space: nowrap; /* Prevents the text from wrapping */
  padding: 4px 8px; /* Optional: Adjust padding for visual appeal */
  margin-right: 1rem;
  min-width: 45%;
  max-width: 80%;
  /* text-align: center; */
  text-wrap: wrap !important;
}

.bot-message-prompt .warranty-status-prompt {
  display: block !important;
  min-width: 45%;
  max-width: 45%;
}

.bot-message-prompt p:hover {
  background-color: #872ff7 !important;
  color: #ffffff !important;
}

.bot-message-card {
  max-width: 80% !important;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}
.product-option {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #ffffff !important;
  color: #1a73e8 !important;
}

.product-option:hover {
  background-color: #872ff7 !important;
  color: #ffffff !important;
}

.chat-window::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

.chat-window::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  height: 8px;
}

.chat-window::-webkit-scrollbar-thumb {
  background-color: #3b3b3b9d;
}

.chat-window::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  border-radius: 10px;
}

.other-icons-dropdown-btn.dropdown {
  position: relative;
}

.other-icons-dropdown-btn .dropdown-menu.show {
  /* min-width: 50px !important; */
  height: 140px !important;
  top: 75% !important;
  width: 85% !important;
  left: 50% !important;
  transform: translate(-50%, -25%) !important;
}

.other-icons-dropdown-btn .dropdown-toggle {
  background: transparent;
  border: none;
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.3 145.57" fill="%23888888"%3E%3Cpath d="M110.3,19.6c0-10.8-8.8-19.6-19.6-19.6H21.81v21.81H0v123.76h88.49v-21.81h21.81V19.6ZM80.15,137.24H8.33V30.15H74.89c2.9,0,5.26,2.36,5.26,5.26v101.83Zm21.81-21.81H88.48V35.41c0-7.5-6.1-13.6-13.6-13.6H30.15V8.33h60.55c6.21,0,11.27,5.06,11.27,11.27v95.83Z"/%3E%3Cpath d="M63.08,98.36H25.41c-2.3,0-4.17,1.87-4.17,4.17s1.87,4.17,4.17,4.17H63.08c2.3,0,4.17-1.87,4.17-4.17s-1.87-4.17-4.17-4.17Z"/%3E%3Cpath d="M63.08,118.4H25.41c-2.3,0-4.17,1.87-4.17,4.17s1.87,4.17,4.17,4.17H63.08c2.3,0,4.17-1.87,4.17-4.17s-1.87-4.17-4.17-4.17Z"/%3E%3Cpath d="M44.24,40.65c-12.69,0-23.01,10.32-23.01,23.01s10.32,23.01,23.01,23.01,23.01-10.32,23.01-23.01-10.32-23.01-23.01-23.01Zm-14.67,23.01c0-8.09,6.58-14.67,14.67-14.67,2.53,0,4.92.65,7,1.78l-11.16,11.17v15.78c-6.06-1.8-10.5-7.42-10.5-14.06Zm18.84,14.06v-12.33l8.72-8.72c1.13,2.08,1.78,4.47,1.78,7,0,6.64-4.44,12.26-10.5,14.06Z"/%3E%3C/svg%3E');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  width: 100%;
  z-index: 999999999 !important;
}

.other-icons-dropdown-btn .btn {
  padding: 12px 12px;
  z-index: 999999999 !important;
}

.other-icons-dropdown-btn .dropdown-item {
  padding: 0.25rem 0.5rem !important;
  z-index: 999999999 !important;
}

.other-icons-dropdown-btn .dropdown-item:hover {
  /* background: #e0e7ee; */
}

.dropdown-menu-item {
  border-radius: 15px !important;
  background-color: #f1f0f0 !important;

  /* background-color: #25252b !important; */
  /* color: #ffffff !important; */
}

.dropdown-menu-item:hover {
  background: #cad5e1 !important;
  color: #000000 !important;
  border: 1pxx solid #000000 !important;
}
