.stepper-container {
    display: flex !important;
    align-items: center !important;
    padding: 10px !important;
    background-color: #f5f5f5 !important;
    border-radius: 8px !important;
}

.step {
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
    padding: 0 10px !important;
    color: #888 !important;
}

.step.active .label {
    font-weight: bold !important;
    color: #333 !important;
    font-size: 1rem !important;
}

.step.active .icon {
    color: green !important;
}

.icon,
.step-number {
    font-size: 1.2em !important;
    margin-right: 5px !important;
}

.step-number {
    color: #555 !important;
    font-weight: bold !important;
    background-color: #e0e0e0 !important;
    border-radius: 50% !important;
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.arrow-icon {
    color: #888 !important;
    margin-left: 5px !important;
}

.order-steper {
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
    padding: 0 10px !important;
    color: #888 !important;
}

.order-steper .label {
    font-size: 1rem !important;
}

.order-steper.active .label {
    font-weight: bold !important;
    color: #333 !important;
}

.order-steper.active .check-icon {
    color: green !important;
    /* background-color: #a1ee02;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #90ee26 0 0px 9px;
    border-radius: 50%; */
}
